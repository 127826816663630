import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';

import { AuthService } from '../auth/auth-service.service';
import { GameService } from '../_services/game.service';

import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';

@Injectable()
export class GameGuard implements CanActivate {

    constructor(private router: Router,
        private authService: AuthService,
        private gameService: GameService) { }

    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): boolean|Promise<boolean> {
        const gameId = next.paramMap.get('id');

        let user = this.authService.userInfo;

        console.log('GAMEGUARD-check: '+gameId+', userId:'+user);
        if(user){
            console.log('GAMEGUARD-check: '+gameId+', userId:'+user.uid);
            //console.log('User is connected. What is user right?'+user.role);
            if(this.isAdmin()){
                return true;
            }
            else{
                return new Promise(async (resolve, reject) => {
                    
                    this.gameService.getGame(gameId)
                    .pipe(take(1))
                    .subscribe(game => {
                        console.log('Found game:'+game);
                        if(game && game.assignedUsers && game.assignedUsers.indexOf(user.uid)>=0){
                            console.log('User has access to game');
                            resolve(true);
                        }
                        else{
                            resolve(false);
                            console.log('User access denied');
                            this.router.navigate(['/home']);
                        }
                    });
                });
            }
        }
        return false;
    }

    isAdmin(){
        let user = this.authService.userInfo;
        if(user && user.role){
            //console.log('User is connected. What is user right?'+user.role);
            if(user.role.toUpperCase() == 'ADMIN'){
                return true;
            }
        }
        return false;
    }
}