import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable, BehaviorSubject } from 'rxjs';

import { AngularFirestore } from '@angular/fire/compat/firestore';
import firebase from 'firebase/compat/app';
import * as geofirestore from 'geofirestore';
import { GeoCollectionReference, GeoFirestore } from 'geofirestore';

import { environment } from '../../environments/environment';

import * as moment from 'moment-timezone';

import { TranslateService } from '@ngx-translate/core';

import { map, take, first } from 'rxjs/operators';

import { Game, Picture } from '../classes/index';

import { v4 as uuid } from 'uuid';
import { ThemeService } from './theme.service';
import { LanguageService } from './language.service';

@Injectable()
export class GameService {

	geofirestore: GeoFirestore;
	geocollection: GeoCollectionReference;

	games;

	private _games: BehaviorSubject<Game[]>;
  	public gameList: Observable<Game[]>;

	totalPlayerCount;
	teamCount;
	teamCountInitiated;
	teamCountInitiatedAndCompleted;

	constructor(private http: HttpClient, 
		public translate: TranslateService,
		private afs: AngularFirestore,
		public themeService:ThemeService,
		public languageService: LanguageService) {
			console.log('GameService initialization...');
			this._games = <BehaviorSubject<[]>>new BehaviorSubject([]);
			  this.gameList = this._games.asObservable();
			// Create a Firestore reference
			const firestore = firebase.firestore();
			// Create a GeoFirestore reference
			this.geofirestore = geofirestore.initializeApp(firestore);
	
			// Create a GeoCollection reference
			this.geocollection = this.geofirestore.collection('games');
			this.listGames()
	}

	listGames(){
		this.listenAllGames()
		.pipe(take(1))
		.subscribe(games => {
			this.games = games.sort((a, b) => {
				if(a.name[this.translate.currentLang] && b.name[this.translate.currentLang]){
					if (a.name[this.translate.currentLang].toUpperCase() > b.name[this.translate.currentLang].toUpperCase()) return 1;
					if (a.name[this.translate.currentLang].toUpperCase() < b.name[this.translate.currentLang].toUpperCase()) return -1;
				}
				return 0;
			});
			//this.checkMissingViatorId();
			let totalPlayerCount = 0;
			let totalTeamCount = 0;
			let totalTeamCountInitiated = 0;
			let totalTeamCountInitiatedAndCompleted = 0;
			for(let game of this.games){
				if(game.playerCount>0){
					totalPlayerCount += game.playerCount;
				}
				if(game.teamCount>0){
					totalTeamCount += game.teamCount;
				}
				if(game.teamCountInitiated>0){
					totalTeamCountInitiated += game.teamCountInitiated;
				}
				if(game.teamCountInitiatedAndCompleted>0){
					totalTeamCountInitiatedAndCompleted += game.teamCountInitiatedAndCompleted;
				}
			}
			this.totalPlayerCount = totalPlayerCount;
			this.teamCount = totalTeamCount;
			this.teamCountInitiated = totalTeamCountInitiated;
			this.teamCountInitiatedAndCompleted = totalTeamCountInitiatedAndCompleted;
			this._games.next(this.games);
		});
	}

	checkMissingViatorId(){
		console.log('checkMissingViatorId');
		for(let game of this.games){
			if(game.published && !game.viatorId){
				console.log('Game has no viator id:'+game.name['en']);
			}
		}
	}

	getBackendUrl(){
		return environment.backendUrl;
	}

	getLocalGame(gameId){
		if(this.games){
			return this.games.filter(function( game ) {return game.id == gameId})[0];
		}
		return null;
	}

	getGameByCityId(cityId){
		if(this.games){
			return this.games.filter(function( game ) {return game.cityId == cityId});
		}
		return null;
	}
	listUserGames(userId){
		// Create a GeoCollection reference
		//return this.listenGames(this.geofirestore.collection('games').where('userId', '==', userId));		
		return this.listenGames(this.geofirestore.collection('games').where('assignedUsers', 'array-contains', userId));		
	}
	async listFreeeGame(){
		let freeGames = []
		let episodes
		await this.themeService.listAllEpisodes().subscribe(gameEpisodes => {
			if(gameEpisodes){
				episodes = gameEpisodes
				for (const game of this.games) {
					let episode  = episodes.find(episode => episode.id == game.episodeId)
					if(episode && episode?.open){
						freeGames.push(game)
					}
				}
			}
		});
		return freeGames
	}

	listThemeGames(themeId){
		// Create a GeoCollection reference
		return this.listenGames(this.geofirestore.collection('games').where('themeId', '==', themeId));		
	}

	listenGames(collectionRef){
		return Observable.create((observer) => {
			collectionRef
			.onSnapshot((querySnapshot) => {
				let games = [];
				for (let doc of querySnapshot.docs) { 
					//console.log('received game:'+JSON.stringify(doc.data()));
					//Fix for geofirestore migration
					//Older version document has d (game fields), l and g parameters
					//New version has fields + g
					let data = new Game();
					data.fromJSON(doc.data());
					/*if(doc.data().d){
						data.fromJSON(doc.data().d);
					}
					else{
						data.fromJSON(doc.data());
					}*/
					data.id = doc.id;
					games.push(data);
				}
				observer.next(games.sort((a, b) => {
					if (a.name['fr'] > b.name['fr']) return 1;
					else if (a.name['fr'] < b.name['fr']) return -1;
					else return 0;
				}));
			});
		});
	}

	listTemplateGames(templateId){
		// Create a GeoCollection reference
		return this.afs.collection('games', ref => ref.where("templateId", "==", templateId))
		.snapshotChanges()
		.pipe(map(changes => {
			return changes.map(a => {
				//console.log('received picture:'+JSON.stringify(a.payload.doc.data()));
				const data = a.payload.doc.data() as Game;
				data.id = a.payload.doc.id;
				return data;
			});
		}));;		
	}

	listenAllGames(){
		return this.listenGames(this.geofirestore.collection('games'));
	}

	listAllGames(){		
		return this.afs.collection('games', ref => ref.orderBy('name.'+this.languageService.currentLang, 'asc'))
		.snapshotChanges()
		.pipe(map(changes => {
			return changes.map(a => {
				let data = new Game();
				data.fromJSON(a.payload.doc.data());
				data.id = a.payload.doc.id;
				return data;
			});
		}));
	}

	getGame(gameUid){
		return Observable.create((observer) => {
			this.geocollection.doc(`${gameUid}`)
			.onSnapshot((gameSnapshot) => {
				let data = new Game();
				if(gameSnapshot.data().d){
					if(gameSnapshot.data().d.d){
						console.log('Game has an unexpected child -d- attribute');
						data.fromJSON(gameSnapshot.data().d.d);	
					}
					else{
						data.fromJSON(gameSnapshot.data().d);	
					}
				}
				else{
					data.fromJSON(gameSnapshot.data());
				}
				data.id = gameSnapshot.id;
				observer.next(data);
			});
		});
	}

	getGameById(gameId){
		return this.afs.collection('games').doc(`${gameId}`).get()
		.pipe(map(game => {
			let obj = new Game();
			if(game && game.data()){
				obj = game.data() as Game;
				obj.id = gameId;
				return obj;
			}
			return null;
		}));
	}

	addGame(data) {
		//game.creationDate = firebase.firestore.FieldValue.serverTimestamp()
		//return this.gamesCollection.add({ ...game });
		if(data.latitude && data.longitude){
			data.coordinates = new firebase.firestore.GeoPoint(data.latitude, data.longitude);
		}
		return this.geocollection.add({ ...data });
	}

	updateGame(gameUid, data){
		/*let gameDoc = this.afs.doc(`games/${gameUid}`);
		console.log('Game uid:'+gameDoc.ref.id);
		return gameDoc.update(data);*/
		//let geodata:any = {d:{ ...data }}
		if(data.latitude && data.longitude){
			data.coordinates = new firebase.firestore.GeoPoint(data.latitude, data.longitude);
		}
		if(data.endLatitude && data.endLongitude){
			data.endCoordinates = new firebase.firestore.GeoPoint(data.endLatitude, data.endLongitude);
		}
		//console.log('Game to update:'+JSON.stringify(geodata));
		
		return this.geocollection.doc(`${gameUid}`).update({ ...data });
	}

	getGameChecks(){
		return this.afs.collection(`game_checks`)
		.snapshotChanges()
		.pipe(map(changes => {
			return changes.map(a => {
				const data = a.payload.doc.data() as any;
				data.id = a.payload.doc.id;
				return data;
			});
		}));
	}

	updateGameCheck(gameUid, data){
		return this.afs.doc(`game_checks/${gameUid}`).update(data);
	}

	setGameCheck(gameUid, data){
		return this.afs.doc(`game_checks/${gameUid}`).set(data);
	}

	deleteGameCheck(gameUid) {
		return this.afs.doc(`game_checks/${gameUid}`).delete();
	}

	getLeaderboard(leaderboardUid){
		return this.afs.collection(`leaderboard_templates`).doc(leaderboardUid).valueChanges()
		.pipe(map(leaderboard => {
			let obj = leaderboard as any;
			return obj;
		}));
	}

	/**
	 * Run setMissionChoicesIds on all games
	 * 
	 * @returns {Promise<void>}
	 */
	async setAllMissionChoicesIds()
	{
		const games: Game[] = await this.listenAllGames()
			.pipe(take(1))
			.toPromise()

		let count = 1;
		for(const game of games)
		{
			console.log('Check game ('+count+'/'+games.length+'):'+game.name['fr']);
			let dataToUpdate = await this.setMissionChoicesIds(game.id);
			if(dataToUpdate){
				console.log('Game was updated. sleep 2sec');
				await new Promise(resolve => setTimeout(resolve, 2000));
			}
			count++;
		}
	}

	listGamePictures(gameUid){
		return this.afs.collectionGroup(`pictures`, ref => ref.where('gameId','==',gameUid)).get()
		.pipe(map(collection => {
			const data = [];
			collection.forEach(a => {
				let item = new Picture();
				item.fromJSON(a.data());
				item.id = a.id;
				item.missionId = a.ref.parent.parent.id;
				data.push(item);
			});
			return data;
		}));

		/*.pipe(map(changes => {
			return changes.map(a => {
				//console.log('received mission:'+JSON.stringify(a.payload.doc.data()));
				let data:any = a.payload.doc.data();
				data.id = a.payload.doc.id;
				data.missionId = a.payload.doc.ref.parent.parent.id;
				return data;
			});
		}));*/
	}

	/**
	 * Assign unique IDs to a game's missions' fork selections and riddle answers
	 * 
	 * @param {string} gameId 
	 * @returns {Promise<any[]>}
	 */
	 async setMissionPictureIds()
	 {
		const pictureQuery = await this.afs.collectionGroup(`pictures`)
		.get()
		.toPromise();
		console.log('Found pictures:'+pictureQuery.docs.length);

		var batch = this.afs.firestore.batch();
		let count = 0;
		let total = 0;

		for(let picDoc of pictureQuery.docs){
			total++;
			//let picDoc = pic.payload.doc;
			//console.log('Found pic:'+picDoc.ref.id);
			let missionRef = picDoc.ref.parent.parent;
			//console.log('Mission id:'+missionRef.id);
			if(missionRef){
				console.log('missionRef:'+missionRef.id);
				let gameRef = missionRef.parent.parent;
				//console.log('Game id:'+gameRef.id);
				let gameTypeRef = gameRef.parent;
				//console.log('Game type:'+gameTypeRef.id);
				//console.log(JSON.stringify(picDoc.data()));
				if((picDoc.data() as any).gameId && (picDoc.data() as any).gameId == gameRef.id){
					console.log('Picture already has a ref to game');
				}
				else if((picDoc.data() as any).templateId && (picDoc.data() as any).templateId == gameRef.id){
					console.log('Picture already has a ref to template');
				}
				else{
					if(gameTypeRef.id == 'games'){
						console.log('Picture gameId should be updated! ');
						batch.update(picDoc.ref, {gameId: gameRef.id});
						//await picDoc.ref.update({gameId: gameRef.id});
						console.log('Picture gameId updated!');
						count++;
					}
					else if(gameTypeRef.id == 'game_templates'){
						console.log('Picture templateId should be updated!');
						batch.update(picDoc.ref, {templateId: gameRef.id});
						//await picDoc.ref.update({templateId: gameRef.id});
						console.log('Picture templateId updated!');
						count++;
					}
				}

				if(count>400){
					// Commit the batch
					console.log('Commit 400 batch');
					await batch.commit();
					count = 0;
					break;
				}
			}
		}
		if(count>0){
			// Commit the batch
			console.log('Commit remaining');
			await batch.commit();
		}
		console.log('---END---'+total);
	 }

	/**
	 * Assign unique IDs to a game's missions' fork selections and riddle answers
	 * 
	 * @param {string} gameId 
	 * @returns {Promise<any[]>}
	 */
	async setMissionChoicesIds(gameId: string)
	{
		const missions = await this.afs.collection(`games/${gameId}/missions`)
			.snapshotChanges()
			.pipe(take(1))
			.toPromise()

		const choiceProps = ['forkSelections', 'riddleAnswers', 'riddleClues']

		const updatePromises = [];
		let dataToUpdate = false;

		missions.forEach(async mission => {
			let missionDoc = mission.payload.doc
			let missionData: any = missionDoc.data()
			let updateData: any = {}

			choiceProps.forEach(choiceProp => {
				if(missionData[choiceProp]){
					let requiresUpdate = missionData[choiceProp].some(choice => choice.uid == null)
	
					if(requiresUpdate)
					{
						dataToUpdate = true;
						console.log('Mission '+missionData.name['fr']+' requires update for '+choiceProp);
						//console.log('Before: '+JSON.stringify(missionData[choiceProp]));
						updateData[choiceProp] = missionData[choiceProp];
						updateData[choiceProp]
						.map(choice => {
							///console.log('choice:'+JSON.stringify(choice));
							if(choice.uid == null){
								choice.uid = uuid();
							}
						});
						//console.log('After: '+JSON.stringify(updateData[choiceProp]));
					}
				}
			});

			if(Object.keys(updateData).length > 0){
				console.log('Update mission:'+JSON.stringify(updateData));
				await missionDoc.ref.update(updateData);
			}
			//if(Object.keys(updateData).length > 0) updatePromises.push(missionDoc.ref.update(missionData))
		})
		return dataToUpdate;
		//return Promise.all(updatePromises)
	}

	async fixGameMissionIds()
	{
		const missionQuery = await this.afs.collectionGroup(`missions`, ref => ref.where('scope','==','game')).get()
		.toPromise();
		console.log('Found missions:'+missionQuery.docs.length);

		var batch = this.afs.firestore.batch();
		let count = 0;
		let total = 0;

		for(let missionDoc of missionQuery.docs){
			//let picDoc = pic.payload.doc;
			//console.log('Found pic:'+picDoc.ref.id);
			let missionRef = missionDoc.ref;
			//console.log('Mission id:'+missionRef.id);
			if(missionRef){
				//console.log('missionRef:'+missionRef.id);
				if((missionDoc.data() as any).id !== missionRef.id){
					console.log('We should update mission:'+(missionDoc.data() as any).id+' to '+missionRef.id);
					count++;
					batch.update(missionRef, {id: missionRef.id});
				}

				if(count>400){
					// Commit the batch
					total += count;
					console.log('Commit 400 batch');
					await batch.commit();
					batch = this.afs.firestore.batch();
					count = 0;
					//break;
				}
			}
		}
		if(count>0){
			// Commit the batch
			console.log('Commit remaining');
			total += count;
			await batch.commit();
		}
		console.log('---END--- '+total+' docs to update');
	}

	copyGame(gameUid, newName, desc, asTemplate){
		let url = environment.backendUrl+'copyGame?gameId='+gameUid+'&destName='+newName+'&desc='+desc;
		if(asTemplate){
			url += '&asTemplate=true';
		}
		return this.http.get(url);
	}

	deployGame(templateUid, targetGameId){
		let url = environment.backendUrl+'copyGame?deploy=true&gameId='+templateUid+'&targetGameId='+targetGameId;
		return this.http.get(url);
	}

	refreshStats(gameId)
	{
		const url = environment.backendUrl + 'refreshGameData?gameId=' + gameId
		return this.http.get(url)
	}

	async refreshAllStats(){
		for(let game of this.games){
			console.log('Refresh stats for game '+game.id);
			let res = await this.refreshStats(game.id).pipe(first()).toPromise();
			console.log('RES:'+res);
			await new Promise(resolve => setTimeout(resolve, 2000))
		}
	}

	deleteGame(gameUid) {
		let gameDoc = this.afs.doc(`games/${gameUid}`);
		return gameDoc.delete();
	}

	/*//Save first document in snapshot of items received
	firstInResponse: any = [];
	//Save last document in snapshot of items received
	lastInResponse: any = [];*/

	listGameTeamPictures(videosOnly, gameUid, limit, lastInResponse){
		return this.afs.doc(`games/${gameUid}`).collection('teampictures', ref => {
			if(videosOnly){
				return ref.where("type", "==", "video").orderBy("time", 'desc').limit(limit);
			}
			return ref.orderBy('time','desc').limit(limit);
		}).snapshotChanges()
		.pipe(map(changes => {
			//this.firstInResponse = changes[0].payload.doc;
			if(changes.length>0){
				lastInResponse = changes[changes.length - 1].payload.doc;
				return {
					data: changes.map(a => {
						//console.log('received mission:'+JSON.stringify(a.payload.doc.data()));
						let data:any = a.payload.doc.data() as Picture;
						data.id = a.payload.doc.id;
						return data;
					}),
					lastInResponse: lastInResponse
				};
			}else{
				return {lastInResponse: [], data: []}
			}
		}));
	}

	listMoreGameTeamPictures(gameUid, videosOnly, limit, lastInResponse){
		return this.afs.doc(`games/${gameUid}`).collection('teampictures', ref => {
			if(videosOnly){
				return ref.where("type", "==", "video").orderBy("time", 'desc').startAfter(lastInResponse).limit(limit);
			}
			return ref.orderBy('time','desc').startAfter(lastInResponse).limit(limit);
		})
		.snapshotChanges()
		.pipe(map(changes => {
			//this.firstInResponse = changes[0].payload.doc;
			lastInResponse = changes[changes.length - 1].payload.doc;
			return {
				data: changes.map(a => {
					//console.log('received mission:'+JSON.stringify(a.payload.doc.data()));
					let data:any = a.payload.doc.data();
					data.id = a.payload.doc.id;
					return data;
				}),
				lastInResponse: lastInResponse
			};
		}));
	}

	listAllPictures(videosOnly, limit, lastInResponse){
		// Get all the user's comments, no matter how deeply nested
		return this.afs.collectionGroup('teampictures', ref => {
			if(videosOnly){
				return ref.where("type", "==", "video").orderBy("time", 'desc').limit(limit);
			}
			return ref.orderBy('time','desc').limit(limit);
		})
		.snapshotChanges()
		.pipe(map(changes => {
			//this.firstInResponse = changes[0].payload.doc;
			if(changes.length>0){
				lastInResponse = changes[changes.length - 1].payload.doc;
				console.log('gameService-listAllPictures, lastInResponse:'+lastInResponse);
				return {
					data: changes.map(a => {
						//console.log('received mission:'+JSON.stringify(a.payload.doc.data()));
						let data:any = a.payload.doc.data();
						data.id = a.payload.doc.id;
						return data;
					}),
					lastInResponse: lastInResponse
				};
			}else{
				return {lastInResponse: [], data: []}
			}
		}));
	}
	
	listPicturesTeam(teamId){
		return  this.afs.collectionGroup('teampictures', ref => ref.where('teamId', '==', teamId))
		.snapshotChanges()
		.pipe(map(changes => {
			return changes.map(a => {
				let data:any = a.payload.doc.data();
				data.id = a.payload.doc.id;
				return data;
			});
		}));
	}

	listMoreTeamPictures(videosOnly, limit, lastInResponse){
		return  this.afs.collectionGroup('teampictures', ref => {
			if(videosOnly){
				return ref.where("type", "==", "video").orderBy("time", 'desc').startAfter(lastInResponse).limit(limit);
			}
			return ref.orderBy("time", 'desc').startAfter(lastInResponse).limit(limit);
		})
		.snapshotChanges()
		.pipe(map(changes => {
			//this.firstInResponse = changes[0].payload.doc;
			lastInResponse = changes[changes.length - 1].payload.doc;
			return {
				data: changes.map(a => {
					//console.log('received mission:'+JSON.stringify(a.payload.doc.data()));
					let data:any = a.payload.doc.data();
					data.id = a.payload.doc.id;
					return data;
				}),
				lastInResponse: lastInResponse
			};
		}));
	}

	deletePicture(gameUid, teamPictureId) {
		let gamePicDoc = this.afs.doc(`games/${gameUid}/teampictures/${teamPictureId}`);
		return gamePicDoc.delete();
	}

	getDiagram(gameUid){
		return this.afs.doc(`games/${gameUid}`).collection('diagrams').doc(`default`).valueChanges()
		.pipe(map(diagram => {
			let obj = diagram as any;
			return obj;
		}));
	}

	setDiagram(gameId, diagram) {
		//game.creationDate = firebase.firestore.FieldValue.serverTimestamp()
		return this.afs.doc(`games/${gameId}`).collection('diagrams').doc(`default`).set({ ...diagram });
	}

	listDirections(gameUid) {
		return this.afs.doc(`games/${gameUid}`).collection('directions').snapshotChanges()
		.pipe(map(changes => {
			return changes.map(a => {
				//console.log('received picture:'+JSON.stringify(a.payload.doc.data()));
				const data = a.payload.doc.data();
				data.id = a.payload.doc.id;
				return data;
			});
		}));
	}

	addDirections(gameUid, data){
		//console.log('Create mission image:'+JSON.stringify(data));
		return this.afs.doc(`games/${gameUid}`).collection('directions').add(data);
	}

	updateDirections(gameUid, directionsUid, data){
		return this.afs.doc(`games/${gameUid}`).collection('directions').doc(`${directionsUid}`).update(data);
	}

	createMailing(gameUid, mailing){
		return this.afs.doc(`games/${gameUid}`).collection('mailings').add(mailing);
	}

	uploadDropbox(gameUid, teamId){
		console.log('Call url: '+environment.backendUrl+'exportSlideshow?gameUid='+gameUid+'&teamUid='+teamId);
		return this.http.get(environment.backendUrl+'exportSlideshow?gameUid='+gameUid+'&teamUid='+teamId);
	}

	prepareExportGoogleDrive(gameUid){
		console.log('Call url: '+environment.backendUrl+'prepareExportGoogleDrive?gameUid='+gameUid);
		return this.http.get(environment.backendUrl+'prepareExportGoogleDrive?gameUid='+gameUid);
	}

	uploadGoogleDrive(gameUid, teamId){
		console.log('Call url: '+environment.backendUrl+'exportGoogleDrive?gameUid='+gameUid+'&teamUid='+teamId);
		return this.http.get(environment.backendUrl+'exportGoogleDrive?gameUid='+gameUid+'&teamUid='+teamId);
	}

	updateObjectPicturesUrl(gameUid){
		console.log('Call url: '+environment.backendUrl+'updateObjectPicturesUrl?gameId='+gameUid);
		return this.http.get(environment.backendUrl+'updateObjectPicturesUrl?gameId='+gameUid);
	}

	getLocalDateValue(time, timezone){
		return moment(moment(time).tz(timezone).format('DD/MM/YYYY hh:mm a'),'DD/MM/YYYY hh:mm a');
	}

	getValueWithTimezone(time, timezone){
		return moment.tz(moment(time).format('DD/MM/YYYY hh:mm a'), 'DD/MM/YYYY hh:mm a', timezone);
	}

	getTimeZones(): Observable<[]> {
		return this.http
		  .get<[]>('assets/data/timezones.json');
	}

	getUserTimezone(){
		return moment.tz.guess();
	}
}
