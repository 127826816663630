import Serializable from './serializable';

export class Group extends Serializable {
	id: string;

	customer: string;
	description: string;
	
	type: string;

	eventDate: number;
	attendees: number;

	teamCount: number;
	nbrCharacters: number;
	prefix: string;

	companyPicture;
	adminAvatarUrl: string;
	adminPassword: string;

	creationDate: number;
	
	teams = {};
	position = {};

	getTeamPosition(score){
		//console.log('getTeamPosition:'+score);
		let position = 1;
		for(let key in this.teams){
			if(this.teams[key].score && this.teams[key].score>score){
				//console.log(this.leaderboard[key]+' score is greater:'+this.leaderboard[key].score);
				position++;
			}
		}
		return position;
	}

	getAdminAvatar(){
		if(this.adminAvatarUrl){
			return this.adminAvatarUrl;
		}
		if(this.companyPicture && this.companyPicture.pictureUrl){
			return this.companyPicture.pictureUrl;
		}
		return 'assets/images/admin_avatar.png';
	}
}
