import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import {MapLocation} from "../classes/map.location";
import {Injectable} from "@angular/core";

import { Observable, BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class GeocodingService {

    googleKey: string = 'AIzaSyCzC1yvO6W4Xw-C2ZUjEkjQ89kzqmxVfxo'; //AIzaSyB9D8TqsYb1PAUpeatclIQLlz5Dkb-owcM

    constructor(public http: HttpClient) {
    }

    geocode(address: string, lat:number, lng:number) {
      console.log('Geocode address:'+address);
      console.log('Geocode lng:'+lat);
      console.log('Geocode lng:'+lng);

      let url;
      
      if(address){
         url = "https://maps.googleapis.com/maps/api/geocode/json?address=" + encodeURIComponent(address)+'&key='+this.googleKey;
      }
      else{
         url = "https://maps.googleapis.com/maps/api/geocode/json?latlng=" + lat+','+lng+'&key='+this.googleKey;
      }

      
      return this.http
            .get(url)
            .pipe(map(res => res))
            .pipe(map((result: any) => {
                if (result.status !== "OK") {
                  console.log('Geocode error:'+JSON.stringify(result));
                  throw new Error("unable to geocode address");
                }

                let location = new MapLocation();
                for(let res of result.results[0].address_components){
                  if(res.types.includes('street_number'))
                    location.streetNr = res.long_name;
                  if(res.types.includes('route'))
                    location.street = res.long_name;
                  if(res.types.includes('locality'))
                    location.city = res.long_name;
                  if(res.types.includes('postal_code'))
                    location.zip = res.long_name;
                  if(res.types.includes('country'))
                    location.country = res.long_name;
                }
                location.address = result.results[0].formatted_address;
                location.latitude = result.results[0].geometry.location.lat;
                location.longitude = result.results[0].geometry.location.lng;

                /*let viewPort = result.results[0].geometry.viewport;
                location.viewBounds = L.latLngBounds(
                  {
                    lat: viewPort.southwest.lat,
                    lng: viewPort.southwest.lng},
                  {
                    lat: viewPort.northeast.lat,
                    lng: viewPort.northeast.lng
                  });*/

                return location;
            }));

            /*"address_components" : [
            {
               "long_name" : "4",
               "short_name" : "4",
               "types" : [ "street_number" ]
            },
            {
               "long_name" : "Rue Lemercier",
               "short_name" : "Rue Lemercier",
               "types" : [ "route" ]
            },
            {
               "long_name" : "Saint-Servais",
               "short_name" : "Saint-Servais",
               "types" : [ "locality", "political" ]
            },
            {
               "long_name" : "Namur",
               "short_name" : "NA",
               "types" : [ "administrative_area_level_2", "political" ]
            },
            {
               "long_name" : "Wallonie",
               "short_name" : "Wallonie",
               "types" : [ "administrative_area_level_1", "political" ]
            },
            {
               "long_name" : "Belgique",
               "short_name" : "BE",
               "types" : [ "country", "political" ]
            },
            {
               "long_name" : "5002",
               "short_name" : "5002",
               "types" : [ "postal_code" ]
            }
         ],
         "formatted_address" : "Rue Lemercier 4, 5002 Saint-Servais, Belgique",
         "geometry" : {
            "location" : {
               "lat" : 50.471838,
               "lng" : 4.839316999999999
            },
            "location_type" : "APPROXIMATE",
            "viewport" : {
               "northeast" : {
                  "lat" : 50.4731869802915,
                  "lng" : 4.840665980291502
               },
               "southwest" : {
                  "lat" : 50.4704890197085,
                  "lng" : 4.837968019708497
               }
            }
         },
         "place_id" : "ChIJgdMkO9ybwUcRswyNrTwNDv8",
         "types" : [ "establishment", "health", "point_of_interest" ]*/
   }

   

   /*getDirectionsFromApi(from, to, waypoints){
      //http://maps.googleapis.com/maps/api/directions/json?origin=41.43206,-81.38992&destination=41.43206,-81.38992&mode=walking

      console.log('Directions from:'+JSON.stringify(from));
      console.log('Directions to:'+JSON.stringify(to));
      
      //waypoints an array of LatLng points
      const encodedWaypoints = google.maps.geometry.encoding.encodePath(waypoints);
      console.log('encoded waypoints:'+encodedWaypoints);

      const url = "https://maps.googleapis.com/maps/api/directions/json?origin=" + from.lat()+','+from.lng()+'&destination=' + to.lat()+','+to.lng()+'&mode=walking&waypoints=enc:'+encodedWaypoints+':&key='+this.googleKey;

      console.log('directions url:'+url);
      console.log('directions url length:'+url.length);
      return this.http
      .get(url)
      .map(res => res)
      .map((result: any) => {
         if (result.status !== "OK") {
            console.log('getDirections error:'+JSON.stringify(result));
            throw new Error("unable to get directions");
         }
      });
   }*/

   getDirections(from, to, waypoints): Observable<any>{
      return new Observable(observer => {
        //http://maps.googleapis.com/maps/api/directions/json?origin=41.43206,-81.38992&destination=41.43206,-81.38992&mode=walking
    
        console.log('Directions from:'+JSON.stringify(from));
        console.log('Directions to:'+JSON.stringify(to));
    
        /*let url = "https://maps.googleapis.com/maps/api/directions/json?origin=" + from.lat()+','+from.lng()+'&destination=' + to.lat()+','+to.lng()+'&mode=walking&key='+googleKey;
        
        if(waypoints){
          //waypoints an array of LatLng points
          const encodedWaypoints = google.maps.geometry.encoding.encodePath(waypoints);
          console.log('encoded waypoints:'+encodedWaypoints);
          url += '&waypoints=enc:'+encodedWaypoints+':';
        }*/
    
        const directionsService = new google.maps.DirectionsService();
        console.log('directionsService:'+directionsService);
        directionsService.route({
            origin: {lat: from.lat(), lng: from.lng()},
            destination: {lat: to.lat(), lng: to.lng()},
            waypoints: waypoints ? waypoints : [],
            optimizeWaypoints: true,
            travelMode: google.maps.TravelMode.WALKING
        }, (response, status) => {
            if (status === 'OK') {
              console.log('getDirections result:'+JSON.stringify(response));
              let directionsRes:any = {};
              if(response.routes && response.routes.length>0){
                let route = response.routes[0];
                if(route.legs && route.legs.length>0){
                  directionsRes.distance = route.legs[0].distance.value;
                  directionsRes.duration = route.legs[0].duration.value;
                  let steps = [];
                  for(let step of route.legs[0].steps){
                    /*console.log('step distance:'+step.distance.value);
                    console.log('step duration:'+step.duration.value);
                    console.log('step path:'+step.path);*/
                    let stepRes:any = {};
                    stepRes.distance = step.distance.value;
                    stepRes.duration = step.duration.value;
                    stepRes.path = step.path;
                    steps.push(stepRes);
                  }
                  directionsRes.steps = steps;
                }
              }
              observer.next(directionsRes);
                // If you'll like to display an info window along the route
                // middleStep is used to estimate the midpoint on the route where the info window will appear
                // const middleStep = (response.routes[0].legs[0].steps.length / 2).toFixed();
                // const infowindow2 = new google.maps.InfoWindow();
                // infowindow2.setContent(`${response.routes[0].legs[0].distance.text} <br> ${response.routes[0].legs[0].duration.text}  `);
                // infowindow2.setPosition(response.routes[0].legs[0].steps[middleStep].end_location);
                // infowindow2.open(map);
            } else {
                console.log('Directions request failed due to ' + status);
                observer.next(false);
            }
        });
      });
   }
}
