import Serializable from './serializable';
import {Player} from "./index";

export class Team extends Serializable {
	id: string;
	gameId: string;
	name: string;
	firstName: string;
	lastName: string;
	description: string;
	color: string;
	groupId: string;
	journalData: {} = {};

	fullName: string;
	teamPictureUrl: string;
	missionCount: number;
	riddleCount: number;
	leaderboardPosition: [];
	freeGames: [];
	leaderboard: [];
	
	email: string;
	lang: string;
	phone: string;

	ticketId: string;
	orderTime: number;
	creationDate: number;

	open: boolean;
	initiated: boolean;
	complete: boolean;
	startTime: number;
	startDelayFromOrder: number;
	duration: number;

	invisible: boolean;
	passwordProtected: boolean;
	password: string;
	debugMode: boolean;

	registeredPlayerCount: number;

	score: number;

	startMissionId: string;
	currentMissionId: string;

	paused: boolean;
	pauseDuration:number;

	//Event specific
	loaded: boolean;
	registered: boolean;
	teamCount: number;
	timeslot: string;
	playersPerTeam: number;
	originalTeamId: string;
	sendRegistration: string;
	registrationSentDate: number;

	playerCount: number;
	players: Player[];

	lastActionDate: number;
	lastMessageDate: number;
	messageCount: number;

	unreadMessages: boolean;
	unreadMessageCount: number;

	dropboxUploadStatus:string;
	lastDropboxUpload:number;

	ratingCount: number;
	ratingTotal: number;
	averageRating: number;

	forceReachMissionCount: number;
	forceCompleteMissionCount: number;

	getDuration(){
		if(this.duration){
			return Math.round(this.duration/(1000*60))+'min';
		}
		return '';
	}

	getPauseDuration(){
		if(this.pauseDuration){
			return Math.round(this.pauseDuration/(1000*60))+'min';
		}
		return '';
	}

	getDelayBetweenOrderAndStart(){
		if(this.startDelayFromOrder){
			const delay = (Math.round(this.startDelayFromOrder/(1000*60*6))/10)+'hrs';
		}
		return 0;
	}
}
